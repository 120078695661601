var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "grid",
        attrs: {
          title: "항목별 결과 목록",
          merge: _vm.gridHeaderInfo.merge,
          columns: _vm.gridHeaderInfo.cols,
          data: _vm.gridData,
          gridHeightAuto: true,
          isTop: false,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          hideBottom: true,
        },
        on: { linkClick: _vm.openScenario },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }